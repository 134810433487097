import {  useContext, useState } from 'react'
import { ReactComponent as GridViewIcon } from "../images/grid-view.svg";
import MetricButtons from "./MetricButtons";
import { setViewGrid } from "../store/actions";
import Context from "../store/context";
import { Measurements } from "../types/MeasurementGrafana";

type Props = {
  id: number;
  name: string;
  metrics: Array<any>;
  setDisplay: any;
  measurements: Measurements;
  index: number;
};

const DeviceMetricFull = ({id, name, metrics, setDisplay, measurements, index}: Props) => {
  const { dispatch } = useContext(Context);
  const [activeMetric, setActiveMetric] = useState<keyof Measurements>('airPressure');

  const handleGridViewClick = () => {
    setDisplay('grid')
    dispatch(setViewGrid())
  }

  return (
    <div className="metric-full">
      <div className="header">
        <div className="title">{name}</div>
        <div className="icon" onClick={() => handleGridViewClick()}>
          <GridViewIcon/>
        </div>
      </div>
      <div className="buttons">
        <MetricButtons
          activeMetric={activeMetric}
          setActiveMetric={setActiveMetric}
          id={id}
          key={`${id}-metric-buttons-full`}
          metrics={metrics}
          activeColor={index % 2 === 0 ? 'orange' : 'blue'}
        />
      </div>
      <div className="main">
        <div className="graphic">
          <iframe title={name} className="grafana-panel-full" src={measurements[activeMetric].src}/>
        </div>
        <div className="graphic">
          <div className="title">History Log:</div>
          <iframe title={`${name}-logs`} className="grafana-panel-full" src={measurements.logs.src}/>
        </div>
      </div>
    </div>
  )
}

export default DeviceMetricFull