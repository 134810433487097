import { useCallback, useContext, useEffect, useState } from "react";
import Context from "../store/context";
import DeviceMetricsMenu from "./DeviceMetricsMenu";
import DeviceMetricFull from "./DeviceMetricFull";
import { Measurements } from "../types/MeasurementGrafana";
import { AWSIoTProvider, PubSub } from "@aws-amplify/pubsub";
import { AstraAwsConfig } from "../STConfig";
import { IMainEvent } from "../types/Event";
import { ReactComponent as StationaryIcon } from '../images/stationary.svg';
import { ReactComponent as WalkingIcon } from '../images/walking.svg';
import { ReactComponent as JoggingIcon } from '../images/jogging.svg';
import { ReactComponent as BikingIcon } from '../images/biking.svg';
import { ReactComponent as DrivingIcon } from '../images/driving.svg';
import type { Metrics } from './MetricButtons';

const sleep = async (time: number) => {
  return new Promise<void>((resolve) => () => {
    setTimeout(() => {
      resolve();
    }, time);
  })
};

type Props = {
  id: number;
  name: string;
  topic: string;
  measurements: Measurements;
  setDisplay: any;
  display: string;
  index: number;
};

const resubscribe = async () => {
  await sleep(5000);
  PubSub.removePluggable("AWSIoTProvider");
  await PubSub.addPluggable(
    new AWSIoTProvider({
      aws_pubsub_region: AstraAwsConfig.REGION,
      aws_pubsub_endpoint:
      AstraAwsConfig.AWS_IOT_WS_ENDPOINT,
    })
  );
};

const getInitialMetrics = (measurements: Measurements) => Object.keys(measurements).filter(name => name !== 'logs').map(name => ({
  name,
  title: measurements[name as keyof Measurements].title,
  iframeSrc: measurements[name as keyof Measurements].src,
}));

const DeviceMetrics = ({ id, name, topic, measurements, display, setDisplay, index }: Props) => {
  const { state } = useContext(Context);
  const { view, selectedDevice } = state;
  const isSelected = selectedDevice === name;
  const [activeMetric, setActiveMetric] = useState<keyof Measurements>('airPressure');
  const [metrics, setMetrics] = useState<Metrics>(getInitialMetrics(measurements));
  const [activity, setActivity] = useState(0);

  const emitEvent = useCallback((e: IMainEvent | null) => {
    if (e) {
      const newMetrics: Metrics = [];
      metrics.forEach(metric => {
        const alias = measurements[metric.name as keyof Measurements].alias;
        let newValue = e[alias as keyof IMainEvent];

        const newMetric = {
          ...metric,
          value: newValue ?? metric.value,
        };
        newMetrics.push(newMetric);
      });

      if (e.act != null) {
        setActivity(e.act);
      }

      setMetrics(newMetrics);
    }
  }, [measurements, metrics]);

  useEffect(() => {
    let sub = PubSub.subscribe(topic).subscribe({
      next: (data: any) => {
        emitEvent(data.value);
      },
      error: (error) => {
        console.error(error);
        if (window.navigator.onLine) resubscribe();
      },
      complete: () => console.log("Done"),
    });

    return () => {
      sub.unsubscribe();
    };
  }, [emitEvent, topic]);

  const activeColor = index % 2 === 0 ? 'orange' : 'blue';

  return (
    <>
      {view === "full" && isSelected && display === "full-device-metric" ?
        <DeviceMetricFull
          name={name}
          id={id}
          key={`${id}-device-metrics-full`}
          metrics={metrics}
          setDisplay={setDisplay}
          measurements={measurements}
          index={index}
        />
        :
        <div
          className={`base-container ${view} ${
            view === "full" && !isSelected ? "none" : ""
          }`}
        >
          <DeviceMetricsMenu
            key={`${id}-device-metrics-menu`}
            id={id}
            name={name}
            metrics={metrics}
            setDisplay={setDisplay}
            activeMetric={activeMetric}
            setActiveMetric={setActiveMetric}
            activeColor={activeColor}
            activity={activity}
          />
          <div className="metric-graph-box">
            <div className="header">
              <div className="activity">
                <div className="title">Activity:</div>
                <div className="icons">
                  <div className={`icon ${activeColor} ${activity === 1 && 'selected'}`}><StationaryIcon /></div>
                  <div className={`icon ${activeColor} ${activity === 2 && 'selected'}`}><WalkingIcon /></div>
                  <div className={`icon ${activeColor} ${activity === 4 && 'selected'}`}><JoggingIcon /></div>
                  <div className={`icon ${activeColor} ${activity === 5 && 'selected'}`}><BikingIcon /></div>
                  <div className={`icon ${activeColor} ${activity === 6 && 'selected'}`}><DrivingIcon /></div>
                </div>
              </div>
            </div>
            <div className="graph-box">
              <iframe title={name} className="grafana-panel" src={measurements[activeMetric].src}/>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default DeviceMetrics;
