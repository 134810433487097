import "@aws-amplify/ui-react/styles.css";
import "./App.scss";
import Header from "./components/Header";
import Main from "./components/Main";
import { Authenticator } from "@aws-amplify/ui-react";
import { BrowserRouter as Router } from "react-router-dom";
import background_1 from "./images/background-1.svg";
import background_2 from "./images/background-2.svg";

function App() {
  return (
    <Authenticator>
      {({ signOut, user }) => (
        <Router>
          <div className="App">
            <img alt="" className="background first" src={background_1} />
            <img alt="" className="background second" src={background_2} />
            <Header />
            <Main />
          </div>
        </Router>
      )}
    </Authenticator>
  );
}

export default App;
