import * as ACTION_TYPES from "./actions-types";

export interface IState {
  view: "grid" | "full";
  selectedDevice: string;
}

export interface IAction {
  type: string;
  payload?: any;
}

export const initialState: IState = {
  view: "grid",
  selectedDevice: ""
};

export const reducer = (state: IState, action: IAction): IState => {
  switch (action.type) {
    case ACTION_TYPES.SET_VIEW_GRID:
      return {
        ...state,
        view: "grid",
        selectedDevice: "",
      };
    case ACTION_TYPES.SET_VIEW_FULL:
      return {
        ...state,
        view: "full",
        selectedDevice: action.payload,
      };
    default:
      return state;
  }
};
