import { useReducer, useState } from "react";
import Context from "../store/context";
import { initialState, reducer } from "../store/reducers";
import { STDeviceConfig } from "../STConfig";
import DeviceMetrics from "./DeviceMetrics";
import { ReactComponent as KlikaLogo } from "../images/klika.svg";
import { ReactComponent as AwsLogo } from "../images/aws.svg";
import { ReactComponent as AmazonSidewalkLogo } from "../images/amazon-sidewalk.svg";
import { ReactComponent as STLogo } from "../images/st.svg";

const Main = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {view} = state;
  const [display, setDisplay] = useState<string>("grid");

  return (
    <Context.Provider value={{state, dispatch}}>
      <>
        <div className={`main-wrapper ${view}`}>
          {STDeviceConfig.map((device, index) => (
            <DeviceMetrics {...device} index={index} key={device.id * 11} display={display} setDisplay={setDisplay}/>
          ))}
        </div>
        <div className="under-text">
          <KlikaLogo />
          <STLogo />
          <AwsLogo />
          <AmazonSidewalkLogo className="shift" />
        </div>
      </>
    </Context.Provider>
  );
};

export default Main;
