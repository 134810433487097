import { Measurements } from "./types/MeasurementGrafana";

export class AstraAwsConfig {
  static readonly AWS_IOT_WS_ENDPOINT = 'wss://a1lsj9p3wlozmc-ats.iot.us-east-1.amazonaws.com/mqtt';
  static readonly REGION = 'us-east-1';
}

type Config = Array<{ id: number; name: string; topic: string; measurements: Measurements }>;

export const STDeviceConfig: Config = [
  {
    id: 1,
    name: "SENSORTILE.BOX #1",
    topic: "topics/decoded/6553EF",
    measurements: {
      airPressure: {
        title: 'Air Pressure',
        alias: 'p',
        src: "https://grafana.st.klika-tech.com/d-solo/G1btqkgkK/device-1?orgId=1&panelId=35&from=now-30m&to=now&theme=dark&refresh=1s"
      },
      temperature: {
        title: "Temperature",
        alias: 't',
        src: "https://grafana.st.klika-tech.com/d-solo/G1btqkgkK/device-1?orgId=1&panelId=6&from=now-30m&to=now&theme=dark&refresh=1s"
      },
      humidity: {
        title: "Humidity",
        alias: 'rh',
        src: "https://grafana.st.klika-tech.com/d-solo/G1btqkgkK/device-1?orgId=1&panelId=28&from=now-30m&to=now&theme=dark&refresh=1s"
      },
      accelerometer: {
        title: "Accelerometer",
        alias: 'acc',
        src: "https://grafana.st.klika-tech.com/d-solo/G1btqkgkK/device-1?orgId=1&panelId=29&from=now-30m&to=now&theme=dark&refresh=1s"
      },
      // gyroscope: {
      //   title: "Gyroscope",
      //   alias: 'gyro',
      //   src: "https://grafana.st.klika-tech.com/d-solo/G1btqkgkK/device-1?orgId=1&panelId=26&from=now-30m&to=now&theme=dark&refresh=1s"
      // },
      magnetometer: {
        title: "Magnetometer",
        alias: 'mag',
        src: "https://grafana.st.klika-tech.com/d-solo/G1btqkgkK/device-1?orgId=1&panelId=30&from=now-30m&to=now&theme=dark&refresh=1s"
      },
      noiseLevel: {
        title: "Noise Level",
        alias: 'nl',
        src: "https://grafana.st.klika-tech.com/d-solo/G1btqkgkK/device-1?orgId=1&panelId=24&from=now-30m&to=now&theme=dark&refresh=1s"
      },
      logs: {
        title: "Logs",
        src: "https://grafana.st.klika-tech.com/d-solo/G1btqkgkK/device-1?orgId=1&panelId=31&theme=dark&refresh=1s"
      }
    }
  },
  {
    id: 2,
    name: "SENSORTILE.BOX #2",
    topic: "topics/decoded/8CF0C0",
    measurements: {
      airPressure: {
        title: 'Air Pressure',
        alias: 'p',
        src: "https://grafana.st.klika-tech.com/d-solo/bmfQq9JVk/device-2?orgId=1&panelId=35&from=now-30m&to=now&theme=dark&refresh=1s"
      },
      temperature: {
        title: "Temperature",
        alias: 't',
        src: "https://grafana.st.klika-tech.com/d-solo/bmfQq9JVk/device-2?orgId=1&panelId=6&from=now-30m&to=now&theme=dark&refresh=1s"
      },
      humidity: {
        title: "Humidity",
        alias: 'rh',
        src: "https://grafana.st.klika-tech.com/d-solo/bmfQq9JVk/device-2?orgId=1&panelId=28&from=now-30m&to=now&theme=dark&refresh=1s"
      },
      accelerometer: {
        title: "Accelerometer",
        alias: 'acc',
        src: "https://grafana.st.klika-tech.com/d-solo/bmfQq9JVk/device-2?orgId=1&panelId=29&from=now-30m&to=now&theme=dark&refresh=1s"
      },
      // gyroscope: {
      //   title: "Gyroscope",
      //   alias: 'gyro',
      //   src: "https://grafana.st.klika-tech.com/d-solo/bmfQq9JVk/device-2?orgId=1&panelId=26&from=now-30m&to=now&theme=dark&refresh=1s"
      // },
      magnetometer: {
        title: "Magnetometer",
        alias: 'mag',
        src: "https://grafana.st.klika-tech.com/d-solo/bmfQq9JVk/device-2?orgId=1&panelId=30&from=now-30m&to=now&theme=dark&refresh=1s"
      },
      noiseLevel: {
        title: "Noise Level",
        alias: 'nl',
        src: "https://grafana.st.klika-tech.com/d-solo/bmfQq9JVk/device-2?orgId=1&panelId=24&from=now-30m&to=now&theme=dark&refresh=1s"
      },
      logs: {
        title: "Logs",
        src: "https://grafana.st.klika-tech.com/d-solo/bmfQq9JVk/device-2?orgId=1&panelId=31&theme=dark&refresh=1s"
      }
    }
  }
];
