import React from "react";
import { initialState, IState } from "./reducers";

interface IContext {
  state: IState;
  dispatch: React.Dispatch<any>;
}

const Context = React.createContext<IContext>({
  state: initialState,
  dispatch: () => null,
});
export default Context;
