import { ReactComponent as UserImage } from "../images/user.svg";
import { ReactComponent as AmazonSidewalkLogo } from "../images/amazon-sidewalk.svg";

const Header = () => {
  return (
    <div className="header-component">
      <div className="left">
        <div className="logo">
          <AmazonSidewalkLogo width={311} height={50} />
        </div>
        <div className="separator" />
        <div className="text">Device Intelligence</div>
      </div>
      <UserImage />
    </div>
  );
};

export default Header;
