import {  ReactComponent as TwoSideArrowIcon } from "../images/two-side-arrow.svg";
import { useContext } from "react";
import Context from "../store/context";
import { setViewFull } from "../store/actions";
import MetricButtons, { Metrics } from "./MetricButtons";
import { Measurements } from "../types/MeasurementGrafana";

type EventsProps = {
  id: number;
  name: string;
  metrics: Metrics;
  activeMetric: keyof Measurements;
  setDisplay: Function;
  setActiveMetric: Function;
  activeColor: "orange" | "blue";
  activity: number;
};

const DeviceMetricsMenu = ({ id, name, metrics, setDisplay, activeMetric, setActiveMetric, activeColor, activity }: EventsProps) => {
  const { state, dispatch } = useContext(Context);
  const { view } = state;

  return (
    <div className={`actions ${view}`}>
      <div className="header">
        <p className="title">{name}</p>
        {view === "grid" && (
          <TwoSideArrowIcon
            className="icon"
            onClick={() => {
              setDisplay("full-device-metric")
              dispatch(setViewFull(name))
            }}
          />
        )}
      </div>
      <div className="list">
        <MetricButtons
          key={`${id}-metric-buttons`}
          id={id}
          activeMetric={activeMetric}
          setActiveMetric={setActiveMetric}
          metrics={metrics}
          activeColor={activeColor}
          activity={activity}
        />
      </div>
    </div>
  );
};

export default DeviceMetricsMenu;
