import {SET_VIEW_FULL, SET_VIEW_GRID} from "./actions-types";

export const setViewGrid = () => {
  return {
    type: SET_VIEW_GRID,
  };
};

export const setViewFull = (device: string) => {
  return {
    type: SET_VIEW_FULL,
    payload: device,
  };
};
