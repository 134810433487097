import { ReactComponent as AirPressureIcon } from "../images/air-pressure.svg";
import { ReactComponent as TemperatureIcon } from "../images/temperature.svg";
import { ReactComponent as HumidityIcon } from "../images/humidity.svg";
import { ReactComponent as AccelerometerIcon } from "../images/accelerometer.svg";
// import { ReactComponent as GyroscopeIcon } from "../images/gyroscope.svg";
import { ReactComponent as MagnetometerIcon } from "../images/magnetometer.svg";
import { ReactComponent as NoiseLevel } from "../images/noise-level.svg";
import { ReactComponent as StationaryIcon } from '../images/stationary.svg';
import { ReactComponent as WalkingIcon } from '../images/walking.svg';
import { ReactComponent as JoggingIcon } from '../images/jogging.svg';
import { ReactComponent as BikingIcon } from '../images/biking.svg';
import { ReactComponent as DrivingIcon } from '../images/driving.svg';
import { Measurements } from "../types/MeasurementGrafana";

interface Metric {
  name: string;
  title: string;
  value?: any;
  iframeSrc: string;
};

export type Metrics = Array<Metric>;

type Props = {
  id: number;
  metrics: Metrics;
  activeMetric: keyof Measurements;
  setActiveMetric: Function;
  activeColor: "orange" | "blue";
  activity?: number;
};

const showActiveState = (activity: unknown) => {
  if (typeof activity !== 'number') return false;
  switch (activity) {
    case 2:
    case 4:
    case 5:
    case 6: return true;
    default: return false;
  }
};

const getActivityIcon = (activity: number) => {
  switch (activity) {
    case 1: return <StationaryIcon />
    case 2: return <WalkingIcon />
    case 4: return <JoggingIcon />
    case 5: return <BikingIcon />
    case 6: return <DrivingIcon />
    default: return null;
  }
}

const getMeasurementIcon = (id: number, name: string) => {
  switch (name) {
    case "airPressure":
      return <AirPressureIcon key={`${id}-air-pressure-icon`}/>
    case "temperature":
      return <TemperatureIcon key={`${id}-temperature-icon`}/>
    case "humidity":
      return <HumidityIcon key={`${id}-humidity-icon`}/>
    case "accelerometer":
      return <AccelerometerIcon key={`${id}-accelerometer-icon`}/>
    // case "gyroscope":
    //   return <GyroscopeIcon key={`${id}-gyroscope-icon`}/>
    case "magnetometer":
      return <MagnetometerIcon key={`${id}-magnetometer-icon`}/>
    case "noiseLevel":
      return <NoiseLevel key={`${id}-noise-level-icon`}/>
    default:
      return null
  }
};

const getUnitOfMeasure = (name: string) => {
  switch (name) {
    case "airPressure":
      return " mbar"
    case "temperature":
      return "°C"
    case "humidity":
      return "%"
    case "noiseLevel":
      return " dB"
    default:
      return ""
  }
};

const generateMetricRecord = (metric: Metric) => {
  let { name, value } = metric;

  if (value != null) {
    if (name === "accelerometer" || name === "gyroscope" || name === "magnetometer") {
      return `${value[0].toFixed(0)}, ${value[1].toFixed(0)}, ${value[2].toFixed(0)}`;
    } else if (name === 'temperature') {
    //   value = (value as number * 9/5) + 32;
        value = value;
    }

    return value.toFixed(1) + getUnitOfMeasure(name);
  }

  return <div className="dot-flashing" />;
}

const MetricButtons = ({ id, activeMetric, setActiveMetric, metrics, activeColor, activity }: Props) => {
  const handleClick = (metric: Metric) => {
    setActiveMetric(metric.name);
  }

  return (
    <>
      {metrics.map((metric) => (
        <div key={`${id}-metric-button-${metric.name}`} className={`event-container metrics-button prevent-select
                      ${activeMetric === metric.name ? "selected" : ""}
                      ${activeColor}
                      ${metric.name === 'accelerometer' && showActiveState(activity) ? 'highlight' : ''}
            `}
          onClick={() => handleClick(metric)}
        >
          <div className="icon">
            {getMeasurementIcon(id, metric.name)}
          </div>
          <div className="metric-info-box">
            <div className="title">
              {metric.title}
            </div>
            <div className="data">{generateMetricRecord(metric)}</div>
          </div>
          {metric.name === 'accelerometer' && showActiveState(activity) &&
            <div className="activity-icon">{getActivityIcon(activity as number)}</div>
          }
        </div>
      ))}
    </>
  );
}

export default MetricButtons;